// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_card__dGX5z {\n  width: 100%;\n  max-width: 30rem;\n  margin-top: 30px;\n  margin-left: auto !important;\n  margin-right: auto !important;\n}\n\n.Login_submitBtnContainer__zxX2X {\n  display: flex;\n  justify-content: center;\n}\n\n.Login_loginTableContainer__qPSbE {\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/user/Login.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".card {\n  width: 100%;\n  max-width: 30rem;\n  margin-top: 30px;\n  margin-left: auto !important;\n  margin-right: auto !important;\n}\n\n.submitBtnContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.loginTableContainer {\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Login_card__dGX5z",
	"submitBtnContainer": "Login_submitBtnContainer__zxX2X",
	"loginTableContainer": "Login_loginTableContainer__qPSbE"
};
export default ___CSS_LOADER_EXPORT___;
