// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddDetails_importCsvCard__OC6y0 {\n  height: 100%;\n}\n\n.AddDetails_addBtnContainer__9Y\\+SG {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/settings/AddDetails.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".importCsvCard {\n  height: 100%;\n}\n\n.addBtnContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importCsvCard": "AddDetails_importCsvCard__OC6y0",
	"addBtnContainer": "AddDetails_addBtnContainer__9Y+SG"
};
export default ___CSS_LOADER_EXPORT___;
