// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddTransaction_payNowBtnContainer__s1ObJ {\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/transaction/AddTransaction.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,gBAAgB;AACjB","sourcesContent":[".payNowBtnContainer {\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payNowBtnContainer": "AddTransaction_payNowBtnContainer__s1ObJ"
};
export default ___CSS_LOADER_EXPORT___;
